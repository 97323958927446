import Resources from "Static/Resources";
import medio from "Static/data/medio.json";
import polyglotI18nProvider from "ra-i18n-polyglot";
import { useEffect } from "react";
import { Admin } from "react-admin";
import articLogo from "../src/assets/img/artic-logo.svg";
import CustomLayout from "./components/layout/Layout";
import { messages as englishMessages } from "./language/language-en";
import { messages as spanishMessages } from "./language/language-es";
import authProvider from "./providers/authProvider";
import superDataProvider from "./providers/superDataProvider";
import customRoutes from "./routes/customRoutes";
import LoginWithTheme from "./views/auth/Login";

const messages = {
  en: englishMessages,
  es: spanishMessages,
};

const default_language = medio?.default_language
  ? `${medio?.default_language}`
  : "es";

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  default_language
);

const Version = () => {
  const numVersion = "1.21.7";
  return (
    <div id="versionId">
      <img src={articLogo} alt="artic logo" width={45} />
      <span>
        {` ${new Date().getFullYear()}™ - ${
          default_language === "en"
            ? "All rights reserved"
            : "Todos los derechos reservados"
        } - Versión ${numVersion}`}
      </span>
    </div>
  );
};

const App = () => {
  useEffect(() => {
    const text =
      default_language === "en" ? "Content Manager" : "Gestor de Contenidos";
    document.title = `${text} | ${medio.nombre}`;
  }, []);

  return (
    <>
      <Admin
        title={`Gestor de Contenidos | ${medio.nombre}`}
        loginPage={LoginWithTheme}
        layout={CustomLayout}
        authProvider={authProvider}
        dataProvider={superDataProvider}
        i18nProvider={i18nProvider}
        customRoutes={customRoutes}
      >
        {(permissions) => Resources(permissions)}
      </Admin>
      <Version />
    </>
  );
};

export default App;
